import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const SearchWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.search-wrap": {
    borderTop: '1px solid' + theme.palette.grey[100],
    borderBottom: '1px solid' + theme.palette.grey[100],
    padding: '24px 80px',
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    '.u-form-group': {
        flexGrow: 1,
        marginBottom: 0,
        '.u-form-control': {
            height: 20,
            '&.MuiInputBase-adornedStart': {
                '.MuiInputBase-input': {
                    padding: 0
                }
            },
            fieldset: {
                border: 'none'
            }
        }
    },
    '.action-area': {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        flexShrink: 0
    },
    [theme.breakpoints.down("md")]: {
        padding: 24
    }
  }
}))