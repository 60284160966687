import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const HomePageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.home-page-wrap': {
    '.header-wrap': {
      '.btn': {
        '&.with-icon': {
          '&.icon-only': {
            [theme.breakpoints.down("md")]: {
              border: 'none',
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
                'svg': {
                  'path': {
                    stroke: theme.palette.grey[900]
                  }
                }
              }
            }
          }
        }
      },
      [theme.breakpoints.down("md")]: {
        paddingBottom: 16,
      }
    },
    ".templates-wrapper": {
      ".templates-card": {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        transition: '200ms ease',
        "&:hover": {
          boxShadow: "0px 24px 24px -24px rgba(60, 0, 137, 0.25)",
        },
      },
      ".templates-card-header": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        ".template-default-text": {
          minWidth: "76px",
          height: 24,
          background: "rgba(60, 0, 137, 0.1)",
          borderRadius: 24,
          fontSize: "11px",
          lineHeight: " 16px",
          letterSpacing: "0.5px",
          display: " flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          color: "#3C0089",
        },
      },
      ".templates-card-footer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 40,
        ".tools-container": { 
          display: "flex", 
          alignItems: "center",
          gap: 24,
          '.btn': {
            '&.with-icon': {
              '&.icon-only': {
                padding: 0,
                '.loader-wrap': {
                  width: 24,
                  height: 24
                }
              }
            }
          } 
        },
      },
    },
}
}));
