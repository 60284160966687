import { Box, CircularProgress, Modal, useTheme } from "@mui/material";
import { Button, Icon, Input } from "@ntpkunity/controls";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: 480,
  borderRadius: "10px",
};

export const TableControlModal = ({
  handleOpen,
  open,
  handleClose,
  setHeader,
  setHeaderValues,
  setFooter,
  header,
  headerValues,
  footer,
}) => {
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [columnList, setColumnList] = React.useState([]);
  const [columnError, setColumnError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  const saveTableForm = useForm();
  const addColumnForm = useForm();

  const saveTableFormSubmit = (data: any): void => {
    setIsLoading(true);
    Object.entries(data).filter((e) => {
      if (e[0].toString().includes("header")) {
        header.push(e[1]);
      } else if (e[0].toString().includes("mid")) {
        headerValues.push(e[1]);
      } else if (e[0].toString().includes("footer")) {
        footer.push(e[1]);
      }
    });
    setHeader([...header]);
    setHeaderValues([...headerValues]);
    setFooter([...footer]);
    setTimeout(function () {
      setIsLoading(false);
      handleClose();
    }, 1000);
  };

  const handleDeleteTableInfo = () => {
    setHeader([]);
    setHeaderValues([]);
    setFooter([]);
    setColumnList([]);
    setIsSubmit(false);
  };

  const addColumnFormSubmit = (data: any): void => {
    for (let i = 1; i <= parseInt(data.add_columns); i++) {
      columnList.push(i);
    }
    setColumnList([...columnList]);
    if (columnList.length !== 0) {
      setIsSubmit(true);
      setColumnError(false);
    } else {
      setColumnError(true);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <form onSubmit={addColumnForm.handleSubmit(addColumnFormSubmit)}>
          <Controller
            control={addColumnForm.control}
            name="add_columns"
            render={({ field }) => (
              <Input
                // @ts-ignore
                error={columnError}
                theme={theme}
                fullWidth
                type="number"
                autoComplete="off"
                disabled={isSubmit}
                label={
                  columnError
                    ? "Please Add Number Of Columns!"
                    : "Number of Columns"
                }
                {...field}
              />
            )}
          />
          <Button
            type="submit"
            style={{ margin: "-16px 0px 0px 0px" }}
            disabled={isSubmit}
            theme={theme}
            iconText={<Icon name="AddIcon" />}
          />
        </form>
        <form onSubmit={saveTableForm.handleSubmit(saveTableFormSubmit)}>
          <div style={{ minWidth: "560px", overflow: "auto" }}>
            <table style={{ position: "relative" }}>
              <tr>
                {isSubmit && (
                  <td>
                    <strong>Header</strong>
                  </td>
                )}
                {columnList.map((e) => (
                  <th key={e} style={{ minWidth: "120px" }}>
                    <Controller
                      control={saveTableForm.control}
                      name={`header${e}`}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          autoComplete="off"
                          {...field}
                        />
                      )}
                    />
                  </th>
                ))}
              </tr>
              <tr>
                {isSubmit && (
                  <td>
                    <strong>Header Values</strong>
                  </td>
                )}
                {columnList.map((e) => (
                  <td key={e} style={{ minWidth: "120px" }}>
                    <Controller
                      control={saveTableForm.control}
                      name={`mid${e}`}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          autoComplete="off"
                          {...field}
                        />
                      )}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                {isSubmit && (
                  <td>
                    <strong>Footer</strong>
                  </td>
                )}
                {columnList.map((e) => (
                  <td key={e} style={{ minWidth: "120px" }}>
                    <Controller
                      control={saveTableForm.control}
                      name={`footer${e}`}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          autoComplete="off"
                          {...field}
                        />
                      )}
                    />
                  </td>
                ))}
              </tr>
            </table>
          </div>

          {isSubmit && (
            <div style={{ display: "flex", gap: "14px", marginTop: "14px" }}>
              <Button
                type="submit"
                iconText={
                  isLoading ? (
                   "Saving..."
                  ) : (
                    <Icon name="SaveIcon" />
                  )
                }
                theme={theme}
                secondary
              />

              <Button
                onClick={handleDeleteTableInfo}
                iconText={<Icon name="DeleteIcon" />}
                theme={theme}
                primary
              />
            </div>
          )}
        </form>
      </Box>
    </Modal>
  );
};
