
import { ConfigureTemplateStoragePage } from "@modules/pages";
import CreateTemplatePage from "@modules/pages/createTemplatePage/createTemplatePage";
import TemplexHomePage from "@modules/pages/templexHomePage/templexHomePage";
import UploadTemplatePage from "@modules/pages/uploadTemplate/uploadTemplatePage";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import "../root.component.css";
import { APP_ROUTES } from "./path";
import { RouteToPage } from "./routeToPage.component";

export const Router: FC = () => {
  return (
    <Routes>
      {/* <Route path={APP_ROUTES.TEPLATE_SETUP_TABS} element={<TemplateSetupsTabPage />} /> */}
      <Route
        path={APP_ROUTES.TEMPLEX_HOME_PAGE}
        element={<TemplexHomePage />}
      />
      <Route
        path={APP_ROUTES.CREATE_TEMPLATE_PAGE}
        element={<CreateTemplatePage />}
      />
      <Route
        path={APP_ROUTES.UPLOAD_TEMPLATE}
        element={<UploadTemplatePage />}
      />
      <Route
        path={APP_ROUTES.CONFIGURE_TEMPLATE_STORAGE}
        element={<ConfigureTemplateStoragePage />}
      />
      <Route
        path={APP_ROUTES.ROUTE_TO_PAGE}
        element={<RouteToPage />}
      />
    </Routes>
  );
};
