import * as React from "react";
import { FC } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { setOpenDrawer } from "../../../store/SetupStore/Actions";
import { IPersistentDrawerProps } from "./PersistentDrawer.types";
import { Icon } from "@ntpkunity/controls";
import { Box, Button, Input, Typography } from "@shared/components";
import { useSetupsStore } from "../../../store/SetupStore/SetupsStoreProvider";
const drawerWidth = 424;

const DrawerHeader = styled("div")(({ theme }) => ({
  "&.drawer-header": {
    padding: "24px 65px",
    borderBottom: "1px solid" + theme.palette.grey[100],
    ".u-drawer-title": {
      textAlign: "center",
      textTransform: "capitalize",
    },
    ".u-drawer-close": {
      position: "absolute",
      right: 24,
      width: 24,
      height: 24,
      padding: 0,
      backgroundColor: "transparent !important",
      svg: {
        path: {
          stroke: theme.palette.grey[300],
        },
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },
  },
}));

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "&.custom-drawer": {
    width: drawerWidth,
    ".MuiDrawer-paper": {
      width: drawerWidth,
      backgroundColor: theme.palette.common.white,
      border: "1px solid" + theme.palette.grey[50],
      top: 8,
      right: 8,
      height: "calc(100% - 18px)",
      boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
      borderRadius: 16,
      color: theme.palette.grey[900],
      overflow: "hidden",
    },
    ".drawer-content-wrap": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      ".drawer-body": {
        padding: "40px 40px 10px 40px",
        height: "100%",
        flexGrow: 1,
        overflowY: "hidden",
        overflowX: "hidden",
        position: "relative",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: 9,
          height: 9,
        },
        "&::-webkit-scrollbar-track": {
          padding: "0 0px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          transition: "all 0.3s",
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          borderRadius: 4,
          border: "3px solid transparent",
          backgroundClip: "content-box",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.16)",
        },
        "&:hover": {
          overflowY: "overlay",
          "@-moz-document url-prefix()": {
            overflowY: "auto",
          },
        },
      },
      ".drawer-footer": {
        padding: "32px 40px 40px 40px",
        position: "relative",
        "&::before": {
          display: "block",
          content: "''",
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          height: 0,
          borderBottomColor: theme.palette.grey[100],
          position: "absolute",
          top: 0,
          width: "calc(100% - 80px)",
        },
      },
    },
    ".divider-with-text": {
      borderTop: "1px solid" + theme.palette.grey[100],
      position: "relative",
      textAlign: "center",
      marginTop: 28,
      display: "block",
      marginBottom: 15,
      height: 16,
      ".divider-text": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "10px",
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        backgroundColor: theme.palette.common.white,
        display: "inline-block",
        position: "relative",
        top: -12,
        padding: "0 8px",
      },
    },
  },
}));

const PersistentDrawer: FC<IPersistentDrawerProps> = ({
  openPopUp,
  setPopUpState,
  title,
  customFooter,
  children,
  theme,
  ...props
}) => {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [state, dispatch] = useSetupsStore();
  const handleDrawerClose = () => {
    // setOpen(false);
    dispatch(setOpenDrawer(false));
    setPopUpState(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CustomDrawer
        className="custom-drawer"
        theme={theme}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={openPopUp}
      >
        <DrawerHeader theme={theme} className="drawer-header">
          <Typography variant="h4" component="h4" className="u-drawer-title">
            {title}
            <IconButton
              className="u-drawer-close"
              aria-label="close"
              onClick={handleDrawerClose}
            >
              <Icon name="CloseBlack" />
            </IconButton>
          </Typography>
        </DrawerHeader>
        <Box className="drawer-content-wrap">
          <Box className="drawer-body">{children}</Box>
          <Box className="drawer-footer">{customFooter}</Box>
        </Box>
      </CustomDrawer>
    </Box>
  );
};

export default PersistentDrawer;
