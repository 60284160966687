import { styled } from "@mui/material/styles";

const drawerWidth = 424;

export const DrawerWrap: any = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
  ".btn": {
    "&.btn-primary": {
      background:
        "linear-gradient(100deg, #FC4778 0%, #FC4778 0.01%, #3952F5 100%)",
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: "none",
      "&:hover, &:focus": {
        background:
          "linear-gradient(100deg, #3952F5 0%, #3952F5 0.01%, #FC4778 100%)",
        color: theme.palette.common.white + "!important",
      },
    },
  },
}));
