import { styled } from "@mui/material/styles";
import { IBoxProps } from "@ntpkunity/controls";

export const HeaderWrap = styled(
  'header',
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.header-wrap": {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    padding: '40px 80px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down("md")]: {
        padding: 24,
    },
    '.logo-area': {
        minHeight: 48,
        maxHeight: 48,
        'img': {
            minHeight: 48,
            maxHeight: 48,
            maxWidth: '100%',
            [theme.breakpoints.down("md")]: {
                minHeight: 32,
                maxHeight: 32,
            }
        },
        [theme.breakpoints.down("md")]: {
            minHeight: 32,
            maxHeight: 32,
        }
    },
    '.title-wrap': {
        display: 'flex',
        alignItems: 'center',
        gap: 24,
        '.btn': {
            '&.with-icon': {
                '&.btn-default': {
                    padding: 0
                }
            }
        },
        [theme.breakpoints.down("md")]: {
            gap: 8,
        }
    },
    '.action-area': {
        display: 'flex',
        alignItems: 'center',
        gap: 16, 
        '.btn': {
            '.MuiButton-iconSizeMedium': {
                'svg': {
                    wdith: 24,
                    height: 24
                }
            }
        }
    },
    '.responsive-area': {
        position: 'relative',
        width: '100%',
        paddingTop: 16,
        '.btn': {
            flexGrow: 1,
            '.MuiButton-iconSizeMedium': {
                'svg': {
                    wdith: 24,
                    height: 24
                },
                [theme.breakpoints.down("sm")]: {
                    display: 'none'
                }
            },
            [theme.breakpoints.down("sm")]: {
                padding: '12px 10px'
            }
        },
        '&::before': {
            display: 'block',
            content: '" "',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            borderTopColor: theme.palette.grey[100],
            width: 'calc(100% + 48px)',
            position: 'absolute',
            left: -24,
            top: 0
        }
    },
    '.btn': {
        '&.btn-secondary': {
            backgroundColor: 'transparent'
        }
    }
  }
}))