import { BASE_URL } from "@shared/constants";
import Http from "@shared/helper/http-api";
import { token } from "@shared/utils";
import { useMutation, useQuery, useQueryClient } from "react-query";

const SAVE_S3_CONFIGURATIONS =
  "documents/configuration/storage/s3/create-s3-configuration";
const GET_S3_CONFIGURATIONS =
  "documents/configuration/storage/s3/get-s3-configuration";
const UPDATE_S3_CONFIGURATIONS =
  "documents/configuration/storage/s3/update-s3-Configuration";

export const useSaveS3Configurations = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(SAVE_S3_CONFIGURATIONS, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_S3_CONFIGURATIONS);
      },
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetS3Configurations = () => {
  const { data, error } = useQuery(
    GET_S3_CONFIGURATIONS,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.get<any>(GET_S3_CONFIGURATIONS + `/${token.tenant_id}`);
    },
    { refetchOnWindowFocus: false }
  );
  return { data, error };
};

export const useUpdateS3Configurations = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.patch<any>(UPDATE_S3_CONFIGURATIONS, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_S3_CONFIGURATIONS);
      },
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};
