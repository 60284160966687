import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import { UMSConfigurationComponent,LoginValidatorComponent } from "@ntpkunity/controls-ums";
import { templexTheme } from "@shared/theme";
import { CustomTheme } from "@shared/theme/customTheme";
import { ModalProvider } from "react-modal-hook";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter} from "react-router-dom";
import { Router } from "./router";
import { SetupsStoreProvider } from "./store/SetupStore/SetupsStoreProvider";
import { BaseLayout } from "./layout";
import { SETTINGS } from "@shared/constants";

export default function Root(props) {
  const queryClient = new QueryClient();
  const searchParams = new URLSearchParams(window.location.search);
  const apiKey = searchParams.get("x-api-key"); 
  let token = JSON.parse(localStorage.getItem(SETTINGS))

  return (
  <>
    { apiKey && !token ?
      (
        <QueryClientProvider client={queryClient}>
          <SetupsStoreProvider>
            <App {...props}/>
            <ReactQueryDevtools initialIsOpen={false} />
          </SetupsStoreProvider>
        </QueryClientProvider>
      ):(
        <UMSConfigurationComponent>
          <LoginValidatorComponent url={`${process.env.NEXT_SCREEN_BASE_URL}/`} theme={templexTheme}>
            <QueryClientProvider client={queryClient}>
              <SetupsStoreProvider>
                <App {...props}/>
                <ReactQueryDevtools initialIsOpen={false} />
              </SetupsStoreProvider>
            </QueryClientProvider>
          </LoginValidatorComponent>
        </UMSConfigurationComponent>
      )
  }
  </>
  )
}

const App = () => {

  return (
    <>
      {(
        <>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={templexTheme}>
              <ModalProvider>
                <BrowserRouter>
                  <CustomTheme theme={templexTheme}>
                    <BaseLayout>
                      <Router/>
                    </BaseLayout>
                  </CustomTheme>
                </BrowserRouter>
              </ModalProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </>
      )}
    </>
  );
};
