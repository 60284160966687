export const LOCAL_STORAGE_ITEM_KEY = "settings";
export const SESSION_STORAGE_ITEM_KEY = "unity";

export class LocalStorage {
  getItemByKey(key: string) {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY));
    if (data) return data[key];
    return null;
  }
  setItembyKey(key: string, value: any) {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY));
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      data
        ? JSON.stringify({ ...data, [key]: value })
        : JSON.stringify({ [key]: value })
    );
  }
  removeItembyKey(key: string) {
    const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_KEY));
    localStorage.setItem(
      LOCAL_STORAGE_ITEM_KEY,
      JSON.stringify({ ...data, [key]: undefined })
    );
  }
  clearStorage() {
    localStorage.removeItem(LOCAL_STORAGE_ITEM_KEY);
  }
}

