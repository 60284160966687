import { useTheme } from "@mui/material";
import { Box, Button, Icon, Input, Typography } from "@ntpkunity/controls";
import { SearchProps } from "./search.props";
import { SearchWrap } from "./search.style";

export const Search = ({ actionArea, value, onChange }: SearchProps) => {
  const theme = useTheme();
  return (
    <SearchWrap theme={theme} className="search-wrap">
      <Input
        theme={theme}
        fullWidth
        type="text"
        placeholder="Type your search here..."
        startAdornment={<Icon name="SearchIcon" />}
        value={value}
        onChange={onChange}
      />
      {actionArea && (
        <Box theme={theme} className="action-area">
          {actionArea}
        </Box>
      )}
    </SearchWrap>
  );
};
