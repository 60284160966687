
import { TemplateConfigurationReducer } from "./template-configuration.reducer";
import { ToastReducer } from "./toast.reducer";

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        [prop]: reducers[prop](acc[prop], action),
      };
    }, state);
  };
};

export const setupsReducer = combineReducers({  
  openDrawerSt: TemplateConfigurationReducer,  
  toastProps: ToastReducer,  
});
