import { ReactNode } from 'react'
import  { useTheme } from '@mui/material'
import { BaseLayoutWrap } from './base-layout.style'

export const BaseLayout = ({ children }: { children: ReactNode }) => {
    const theme = useTheme()
  return (
    <BaseLayoutWrap theme={theme} className="base-layout-wrap">
        {children}
    </BaseLayoutWrap>
  )
}
