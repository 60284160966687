import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CreateTemplatePageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.create-template-page-wrap': {
  ".editor-container": {
    ".filter": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: 'wrap',
      gap: 24,
      ".filter-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        flexGrow: 1,
        flexWrap: 'wrap',
        '.u-form-group': {
          marginBottom: 0,
          '.u-form-control': {
            '&.u-select': {
              height: 32,
              '.MuiInputBase-input': {
                padding: '0 35px 0 16px'
              },
              '.MuiSelect-icon': {
                right: 8
              },
              fieldset: {
                borderRadius: 24
              }
            }
          }
        }
      },
    },
    ".switch-container": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      '.MuiSwitch-root': {
        order: 2
      },
      '.MuiFormControlLabel-label': {
        marginLeft: 0,
        marginRight: 8
      }
    },
    ".input-container": {
        '.u-form-group': {
          margin: '24px 0',
          '.u-form-control': {
            height: 'auto',
            fontSize: theme.typography.h2.fontSize, 
            lineHeight: theme.typography.h2.lineHeight,
            fontWeight: theme.typography.fontWeightBold,
            '.MuiInputBase-input': {
              padding: 0
            },
            [theme.breakpoints.down('md')]: {
              fontSize: theme.typography.h3.fontSize,
              lineHeight: theme.typography.h3.lineHeight
            },
            'fieldset': {
              border: 'none'
            }
          }
        },
        "&.with-switch": {
          ".MuiInputBase-root": {
            paddingRight: 0
          }
        }
      }
  },
}
}));
