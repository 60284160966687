import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const BaseLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.base-layout-wrap": {
   '.main-content': {
      paddingLeft: 80,
      paddingRight: 80,
      paddingBottom: 40,
      '.inner-container': {
        padding: 80,
        backgroundColor: theme.palette.common.white,
        borderRadius: 16,
        [theme.breakpoints.down("md")]: {
          padding: 24,
          marginLeft: -24,
          marginRight: -24,
          borderRadius: 0,
          marginBottom: -24
        }
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
      }
    }
  }
}))