
export enum Theme {
  DARK = "dark",
  DEFAULT = "default",
}

export enum Validation {
  REQUIRED = "required",
  EMAIL = "email",
  WHITE_SPACE = "whiteSpace",
  ONLY_ALPHABETS = "onlyAlphabets",
  PHNONE_NUMBER = "phoneNumber",
  LIMIT_LENGTH = "limitLength",
  CROSS_VALIDATE = "crossValidate",
}

export enum PasswordKey {
  Text = "text",
  Password = "password",
}

export enum ControlAction {
  EDIT = "Edit",
  SAVE = "Save",
  ADD = "Add",
  DELETE = "Delete",
  VIEW = "View",
  SAVE_AS = "Save As",
  CANCEL = "Cancel",
  SAVE_AS_DRAFT = "Save As Draft",
  UPLOAD_FILE = "Upload File",
  ADD_MODEL = "Add Configuration",

}

export enum ActionTypes {
  SET_TOAST = "SET TOAST",
  SET_DRAWER_OPEN = "SET DRAWER OPEN",
}

export enum ActionOptions {
  ADD = "Add",
  EDIT = "Edit",
  DELETE = "Delete",
  ADD_NEW_CHART = "Add New Chart",
  RENAME_CHART = "Rename Chart",
  ADD_CRITERIA = "Add Criteria",
  VIEW = "View",
  SAVE_AS = "Save As",
  CANCEL = "Cancel",
  SAVE_AS_DRAFT = "Save As Draft",
  ADD_MODEL = "Add Model",
  ADD_TRIM = "Add Trim",
  UPLOAD_FILE = "Upload File",
  DOWNLOAD_INVENTORY_FILE = "Download Template",
  USER_PROFILE = "MyProfile",
  CHANGE_PASSWORD = "ChangePassword",
  LOGOUT = "Logout",
  ADD_PROVIDER = "Add Provider",
}

export enum DialogMessages {
  confirmationTitle = "Are you sure you want to delete?",
  updateTitle = "Are you sure you want to update?",
  confirmationText = "Once deleted, you cannot undo this action.",
  deleteBtnText = "Delete",
  updateBtnText = "Update",
  saveBtnText = "Save",
  saveTitle = "Do you want to leave this page?",
  saveText = " You have unsaved changes. Would you like to save them before exiting?",
  uploadTitle = "Are you sure you want to upload?",
  uploadWarningText = "The file you are uploading will override the existing records. Are you sure you want to continue?",
  uploadBtnText = "Upload",
}