import { APP_ROUTES } from "@router/path";
import { SETTINGS } from "@shared/constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RouteToPage = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = JSON.parse(localStorage.getItem(SETTINGS))
  let pagePath = params.get("page-path");
  const navigate = useNavigate();
  useEffect(() => {
    if (token.access_token) {
      if (pagePath === "create") navigate(APP_ROUTES.CREATE_TEMPLATE_PAGE);
      else if (pagePath === "") navigate(APP_ROUTES.TEMPLEX_HOME_PAGE);
      else navigate(`/${pagePath}`);
    }
  }, []);
  return <></>;
};
