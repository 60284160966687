import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import {
  MenuItem,
} from "@mui/material";


export const SectionContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.section-content": {
    borderBottom: "1px solid" + theme.palette.grey[100],
    "&:last-child": {
      border: "none",
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        marginBottom: 0,
        ".MuiFormControlLabel-label": {
          color: theme.palette.grey[900],
        },
      },
    },
    ".form-cta": {
      ".btn": {
        marginTop: 32,
      },
    },
    ".u-form-group": {
      ".u-form-control": {
        "&.MuiInputBase-adornedEnd": {
          svg: {
            path: {
              stroke: theme.palette.grey[300],
            },
          },
          cursor: "pointer",
          ".MuiButtonBase-root": {
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
            ".MuiTouchRipple-root": {
              display: "none",
            },
          },
        },
      },
    },
    'input[type="password"]::-ms-reveal': {
      display: "none",
    },
    'input[type="password"]::-ms-clear': {
      display: "none",
    },
  },
}));


export const MenuItemCenter = styled(
  MenuItem,
  {}
)<any>(({ theme }) => ({
  color: theme.palette.common.black,
}));

export const CustomButton = styled(
  "div",
  {}
)<any>(({ theme }) => ({
  "&.custom-buttons": {
      position: "relative",
      ".button-group": {
          ".group-btn": {
              width: "auto",
              padding: "12px 24px",
              cursor: "pointer",
              "&:not(:last-of-type)": {
                  flexGrow: 1,
              },
              "&.only-icon": {
                  padding: 12,
                  "&.btn-secondary": {
                      svg: {
                          path: {
                              stroke: theme.palette.grey[900],
                          },
                      },
                      "&:hover": {
                          svg: {
                              path: {
                                  stroke: theme.palette.common.white,
                              },
                          },
                          "&:focus": {
                              svg: {
                                  path: {
                                      stroke: theme.palette.common.white,
                                  },
                              },
                          },
                      },
                      "&:focus": {
                          svg: {
                              path: {
                                  stroke: theme.palette.common.white,
                              },
                          },
                      },
                      "&:focus-visible": {
                          svg: {
                              path: {
                                  stroke: theme.palette.common.white,
                              },
                          },
                      },
                      "&:disabled": {
                          svg: {
                              path: {
                                  stroke: theme.palette.grey[300],
                              },
                          },
                      },
                  },
              },
              "&.btn-secondary": {
                  backgroundColor: "transparent",
                  borderColor: theme.palette.grey[300],
                  color: theme.palette.grey[900],
                  "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                  },
                  "&:focus-visible": {
                      backgroundColor: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                  },
                  "&:focus": {
                      backgroundColor: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                  },
                  "&:disabled": {
                      borderColor: theme.palette.grey[100],
                      backgroundColor: "transparent",
                      color: theme.palette.grey[300],
                  },
                  "&.disabled": {
                      borderColor: theme.palette.grey[100],
                      backgroundColor: "transparent",
                      color: theme.palette.grey[300],
                  },
              },
          },
      },
      ".u-dropdown-menu": {
          ".MuiPaper-root": {
              borderStyle: "solid",
              borderWidth: 1,
              borderColor:
                  theme.palette.mode === "dark"
                      ? [theme.palette.common.black]
                      : theme.palette.grey[50],
              boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
              minWidth: 145,
              padding: 8,
              color: theme.palette.grey[600],
              scrollbarWidth: "thin",
              ".MuiList-root": {
                  padding: 0,
              },
              "&::-webkit-scrollbar": {
                  width: 7,
                  height: 7,
              },
              "&::-webkit-scrollbar-track": {
                  padding: "0 1px",
                  backgroundColor: "#f0f0f0",
              },
              "&::-webkit-scrollbar-thumb": {
                  transition: "all 0.3s",
                  backgroundColor: "#cdcdcd",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#a6a6a6",
              },
          },
          ".u-dropdown-item": {
              padding: "12px 24px 12px 16px",
              borderRadius: theme.shape.borderRadius,
              // backgroundColor: theme.palette.common.white,
              color: theme.palette.grey[600],

              "&:hover, &.Mui-selected": {
                  backgroundColor:
                      theme.palette.mode === "dark" ? theme.palette.grey[100] : "#F2F4FA",
                  color:
                      theme.palette.mode === "dark"
                          ? theme.palette.common.white
                          : theme.palette.grey[900],
              },

              ".MuiTouchRipple-root": {
                  display: "none",
              },
          },
      },
  },
}));