export const APP_ROUTES = {
  CONFIGURE_TEMPLATE: "/dock/configure-template",
  CONFIGURE_TEMPLATE_STORAGE: "/dock/configure-template-storage",
  DOC_EDITOR: "/dock/doc-editor",
  TEPLATE_SETUP_TABS: "/dock/template-setup-tabs",
  TEMPLEX_HOME_PAGE: "/dock",
  UPLOAD_TEMPLATE: "/dock/upload",
  CREATE_TEMPLATE_PAGE: "/dock/create",
  ROUTE_TO_PAGE: "dock/route-to-page",
};
