import { LocalStorage } from "./browserStorage";

const _localStorageService: LocalStorage = new LocalStorage();

export const getItem = (key: string) => _localStorageService.getItemByKey(key);

export const setItem = (key: string, value: any) =>
  _localStorageService.setItembyKey(key, value);

export const removeItem = (key: string) =>
  _localStorageService.removeItembyKey(key);

export const clearLocalStorage = () => _localStorageService.clearStorage();

export const getItemByKey = (key: string) => {
  const data = JSON.parse(localStorage.getItem(key));
  if (data) return data;
  return null;
};

export const setItembyKey = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeItembyKey = (key: string) => {
  localStorage.removeItem(key);
};
