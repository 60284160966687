import { useTheme } from "@mui/material"
import { HeaderWrap } from "./header.style"
import { Box, Button, Icon, Typography } from "@ntpkunity/controls"
import { HeaderProps } from "./header.props"

export const Header = ({logo, onLogoClick, title, onBack, actionArea, responsiveArea}: HeaderProps) => {
  const theme = useTheme()
  return (
    <HeaderWrap theme={theme} className="header-wrap" component={'header'}>
      {logo &&
        <Box theme={theme} className="logo-area" onClick={onLogoClick}>
          <img src={logo} alt="Templex" />
        </Box>
      }
      {onBack &&
        <Box theme={theme} className="title-wrap">
          <Button theme={theme} defaultBtn iconText={<Icon name="IconLeftArrow" />} onClick={onBack} />
          {title &&
            <>{title}</>
          }
        </Box>
      }
      {actionArea &&
        <Box theme={theme} className="action-area">
          {actionArea}
        </Box>
      }
      {responsiveArea &&
        <Box theme={theme} className="responsive-area" display={{xs:'flex', md: 'none'}} alignItems={'center'} gap={2}>
          {responsiveArea}
        </Box>
      }
    </HeaderWrap>
  )
}
