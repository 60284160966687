import { StoreActionType } from "../../StoreActionType";
import { IToast } from "../../ducks/ITosat";
import { ActionTypes } from "@shared/constants";

export const ToastReducer = (
  state: IToast,
  action: StoreActionType
): IToast => {
  switch (action.type) {
    case ActionTypes.SET_TOAST:
      return (state = action.payload);
    default:
      return state;
  }
};
