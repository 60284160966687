import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-wrap': {
  ".main-container": {
    '&.inner-container': {
      minHeight: 'calc(100vh - 170px)',
      maxHeight: 'calc(100vh - 170px)',
      [theme.breakpoints.down('md')]: {
        minHeight: 'calc(100vh - 97px)',
        maxHeight: 'calc(100vh - 97px)',
      }
    }
  },
  ".custom-checkbox-wrap": {
    ".u-custom-control-label": {
      marginBottom: 0,
      ".MuiFormControlLabel-label": {
        color: theme.palette.grey[900],
      },
    },
  },
  ".form-btn": {
    ".btn": {
      marginTop: 32,
    },
  },
  ".u-form-group": {
    ".u-form-control": {
      "&.MuiInputBase-adornedEnd": {
        svg: {
          path: {
            stroke: theme.palette.grey[300],
          },
        },
        cursor: "pointer",
        ".MuiButtonBase-root": {
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
          ".MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
  },
  'input[type="password"]::-ms-reveal': {
    display: "none",
  },
  'input[type="password"]::-ms-clear': {
    display: "none",
  },
}
}));
