import { Theme } from "@mui/system";
import {
    IToast,
} from "./ducks/ITosat";

export default interface IActions {
    payload: unknown;
    type: string;
}

export interface IThemeConfig {
    theme: Theme;
}

export interface IBrowserStorage {
    localStorage: any;
    sessionStorage: any;
}

// TODO: add relevant types
export type StoreContextType = {
    actions: {
        setToast: (data: IToast) => void;
    };
    states: {
        toastData: IToast;
    };
};

// TODO: add relevant default values
export const defaultStoreValues = {
    states: {
        toastData: {
            toastMessage: "",
            toastState: false,
            toastLength: undefined,
            variant: undefined,
        }
    },
    actions: {
        setToast: (): void => undefined,
    },
};
