import { StoreActionType } from "../../StoreActionType";
import { ActionTypes } from "@shared/constants";

export const TemplateConfigurationReducer = (
  state: boolean,
  action: StoreActionType
): boolean => {
  switch (action.type) {
    case ActionTypes.SET_DRAWER_OPEN:
      return (state = action.payload);
    default:
      return state;
  }
};
